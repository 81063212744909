import { useCallback, useMemo } from "react"
import { useGoogleReCaptcha } from "react-google-recaptcha-v3"
import { useMutation } from "react-query"
import { fetchGVerify, GVerifyReturnType } from "../api/reCaptchaAPI"

type UseGRecaptchaType = (props?: {
  cbSuccess?: () => void
  cbError?: (res: GVerifyReturnType) => void
  cbMutate?: () => void
}) => {
  run: () => void
  isLoading: boolean
}

export const useGRecaptcha: UseGRecaptchaType = ({
  cbSuccess,
  cbMutate,
  cbError,
} = {}) => {
  const { executeRecaptcha } = useGoogleReCaptcha()

  const { mutate: verifyMutate, isLoading } = useMutation(fetchGVerify, {
    onSuccess: (res) => {
      if (res.status === "success") {
        if (cbSuccess) {
          cbSuccess()
        }
      }

      if (res.status === "failure") {
        if (cbError) {
          cbError(res)
        }
      }
    },
    onMutate: () => {
      if (cbMutate) {
        cbMutate()
      }
    },
  })

  const run = useCallback(() => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available")
      return
    }
    void executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
      console.log(gReCaptchaToken, "response Google reCaptcha server")
      verifyMutate({
        token: gReCaptchaToken,
      })
    })
  }, [executeRecaptcha, verifyMutate])

  return useMemo(
    () => ({
      run,
      isLoading,
    }),
    [isLoading, run],
  )
}
