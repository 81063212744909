export type GVerifyReturnType = {
  status: "success" | "failure"
  message?: string
}
export const fetchGVerify = ({
  token,
}: {
  token: string
}): Promise<GVerifyReturnType> =>
  fetch("/api/gverify", {
    method: "POST",
    headers: {
      Accept: "application/json, text/plain, */*",
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      gRecaptchaToken: token,
    }),
  }).then((res) => res.json())
