import { FC } from "react";
import { cx } from "@linaria/core";
import { cssIsActive } from "../../../styles/utils/Utils";
import { Icon } from "../../ui/Icon";
import { Typography } from "../../ui/Typography/Typography";
import { StyledSuccessOverlay } from "./StyledSuccessOverlay";
export const SuccessOverlay: FC<{
  isSuccess: boolean;
  message?: string;
}> = ({
  isSuccess,
  message
}) => {
  return <>
      {isSuccess && <>
          <StyledSuccessOverlay className={cx(isSuccess && cssIsActive)}>
            <Icon NameIcon={"Check"} size={"large"} />
            <Typography variant={"default"}>
              {!!message ? message : "Данные успешно отправлены!"}
            </Typography>
          </StyledSuccessOverlay>
        </>}
    </>;
};