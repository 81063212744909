import { FC } from "react"
import {
  PaymentIcons,
  PaymentsGroupTitle,
} from "../../layouts/Default/Footer/StyledFooter"
import { Icon } from "../ui/Icon"

export const Payments: FC = () => {
  return (
    <>
      <PaymentsGroupTitle>Способы оплаты</PaymentsGroupTitle>
      <PaymentIcons>
        <Icon NameIcon={"PayApple"} />
        <Icon NameIcon={"PayGoogle"} />
        <Icon NameIcon={"PayMastercard"} />
        <Icon NameIcon={"PayMaestro"} />
        <Icon NameIcon={"PayVisa"} />
        <Icon NameIcon={"PayMir"} />
      </PaymentIcons>
    </>
  )
}
